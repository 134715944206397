import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Addgift from './Components/Addgift'
import AddGiftDetails from './Components/App'
import "./Components/style.css"

export default function App() {
  return (
    <div>
      <Routes>
        {/* Default route, where the main content appears */}
        <Route path="/" element={<Addgift />} />
        {/* Route for add-gift-details page */}
        <Route path="/gift" element={<AddGiftDetails />} />
      </Routes>
      {/* <Addgift/>
      <AddGiftDetails/> */}
    </div>
  )
}

