// export default VideoRecorder;
import React, { useState, useRef, useEffect } from 'react';
import { ReactMediaRecorder } from 'react-media-recorder';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VideoRecorder = ({ url, isFirstTime, retake }) => {
  const [videoUrl, setVideoUrl] = useState(null); 
  const [isRecording, setIsRecording] = useState(false); 
  const fileInputRef = useRef(null); 
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [showUploadButtons, setShowUploadButtons] = useState(true);
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [ showBlockInfo, setShowBlockInfo] = useState(null);
  const [hasMediaAccess, setHasMediaAccess] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  // Retrieve video from local storage when component mounts
  useEffect(() => {
    const storedVideo = localStorage.getItem('recordedVideo');
    if (storedVideo) {
      setVideoUrl(storedVideo); 
      url(storedVideo); 
    }

    const uploadedVideo = localStorage.getItem('uploadedVideo');
    console.log(uploadedVideo,"uploadedVideo")
    if (uploadedVideo) {
      setUploadedVideo(uploadedVideo); 
      url(uploadedVideo); 
    }

    // Check for camera and microphone access
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then(() => {
        setShowBlockInfo(true);
        setHasMediaAccess(true);
      })
      .catch(() => {
        setShowBlockInfo(false);
        setHasMediaAccess(false);
      });

  }, [url]);

  const handleStart = () => {
    console.log(showUploadButtons, "Recording started");
    if (showUploadButtons) {
      const modalContent = document.querySelector('.modal-content1');
      if (modalContent) {
        modalContent.style.display = 'none';
      }
    }
    setIsPreviewVisible(true);

    const videoDiv = document.querySelector('.videoDiv');
    if (videoDiv) {
      videoDiv.style.display = 'block'; // Change to 'block' to make it visible
    }
  };

  const handleStop = (blobUrl) => {
    console.log("Recording stopped. Blob URL:", blobUrl);
    setVideoUrl(blobUrl);

    url(blobUrl) // Store the recorded video URL for preview
    localStorage.setItem('recordedVideo', blobUrl);
    setShowUploadButtons(false);
    const videoPreviewSection = document.querySelector('.videoDiv');
    const videoElement = videoPreviewSection.querySelector('video');
    if (videoElement) {
      videoPreviewSection.style.display = 'none';
    }
    setIsPreviewVisible(false);
  };

  const handleButtonClick = () => {
    console.log(showUploadButtons, "upload btn");
    if (showUploadButtons) {
      const modalContent = document.querySelector('.modal-content1');
      if (modalContent) {
        console.log("if")
        modalContent.style.display = 'none';
      }
      const previewSection = document.querySelector('.videoDiv');
      if (previewSection) {
        console.log("if 2nd")
        previewSection.style.display = 'block';
      }
    }
    fileInputRef.current.click();
  };


  // Handle video file upload
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const uploadedVideoUrl = URL.createObjectURL(file); // Create object URL for preview
      setUploadedVideo(uploadedVideoUrl); // Store uploaded video URL
      url(uploadedVideoUrl); // Pass to parent
      localStorage.setItem('uploadedVideo', uploadedVideoUrl); // Save to local storage
      console.log("Uploaded video file:", file);
      setShowUploadButtons(false);
    }
  };


  // Handle retake video
  const handleRetake = () => {
    retake(true);
    setIsRecording(false);
    setVideoUrl(null);
    setUploadedVideo(null);

    localStorage.removeItem('recordedVideo');
    localStorage.removeItem('uploadedVideo');

    setShowUploadButtons(true);

    // Show the recording section again
    setIsRecordingVisible(true);
    // setIsPreviewVisible(false);

    const modalContent = document.querySelector('.modal-content1');
    if (modalContent) {
      modalContent.style.display = 'block';
    }
  };

  // Handle save functionality (you can extend this based on requirements)
  const handleSave = () => {
    // Show popup
    document.getElementById('uploadPopup').style.display = 'block';

    // Simulate upload progress (you can replace this with actual upload progress)
    let progress = 0;
    const progressBar = document.querySelector('.progress-bar');

    const interval = setInterval(() => {
      progress += 10;
      progressBar.style.width = progress + '%';

      if (progress >= 100) {
        clearInterval(interval);

        // Remove existing saved videos
        const existingVideos = document.querySelectorAll('.saved-video');
        existingVideos.forEach((video) => video.remove());

        const videoContainer = document.createElement('div');
        videoContainer.className = 'saved-video';

        const videoElement = document.createElement('video');
        videoElement.src = uploadedVideo ? uploadedVideo : videoUrl;
        videoElement.controls = true;
        videoElement.width = 300;

        // Create a delete button
        const deleteButton = document.createElement('button');
        deleteButton.innerHTML = `<svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="SVGRepo_bgCarrier" stroke-width="0"/>
<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
<g id="SVGRepo_iconCarrier"> 
  <path d="M12 2.75C11.0215 2.75 10.1871 3.37503 9.87787 4.24993C9.73983 4.64047 9.31134 4.84517 8.9208 4.70713C8.53026 4.56909 8.32557 4.1406 8.46361 3.75007C8.97804 2.29459 10.3661 1.25 12 1.25C13.634 1.25 15.022 2.29459 15.5365 3.75007C15.6745 4.1406 15.4698 4.56909 15.0793 4.70713C14.6887 4.84517 14.2602 4.64047 14.1222 4.24993C13.813 3.37503 12.9785 2.75 12 2.75Z" fill="#ffffff"/> 
  <path d="M2.75 6C2.75 5.58579 3.08579 5.25 3.5 5.25H20.5001C20.9143 5.25 21.2501 5.58579 21.2501 6C21.2501 6.41421 20.9143 6.75 20.5001 6.75H3.5C3.08579 6.75 2.75 6.41421 2.75 6Z" fill="#ffffff"/> 
  <path d="M5.91508 8.45011C5.88753 8.03681 5.53015 7.72411 5.11686 7.75166C4.70356 7.77921 4.39085 8.13659 4.41841 8.54989L4.88186 15.5016C4.96735 16.7844 5.03641 17.8205 5.19838 18.6336C5.36678 19.4789 5.6532 20.185 6.2448 20.7384C6.83639 21.2919 7.55994 21.5307 8.41459 21.6425C9.23663 21.75 10.2751 21.75 11.5607 21.75H12.4395C13.7251 21.75 14.7635 21.75 15.5856 21.6425C16.4402 21.5307 17.1638 21.2919 17.7554 20.7384C18.347 20.185 18.6334 19.4789 18.8018 18.6336C18.9637 17.8205 19.0328 16.7844 19.1183 15.5016L19.5818 8.54989C19.6093 8.13659 19.2966 7.77921 18.8833 7.75166C18.47 7.72411 18.1126 8.03681 18.0851 8.45011L17.6251 15.3492C17.5353 16.6971 17.4712 17.6349 17.3307 18.3405C17.1943 19.025 17.004 19.3873 16.7306 19.6431C16.4572 19.8988 16.083 20.0647 15.391 20.1552C14.6776 20.2485 13.7376 20.25 12.3868 20.25H11.6134C10.2626 20.25 9.32255 20.2485 8.60915 20.1552C7.91715 20.0647 7.54299 19.8988 7.26957 19.6431C6.99616 19.3873 6.80583 19.025 6.66948 18.3405C6.52891 17.6349 6.46488 16.6971 6.37503 15.3492L5.91508 8.45011Z" fill="#ffffff"/> 
  <path d="M9.42546 10.2537C9.83762 10.2125 10.2051 10.5132 10.2464 10.9254L10.7464 15.9254C10.7876 16.3375 10.4869 16.7051 10.0747 16.7463C9.66256 16.7875 9.29502 16.4868 9.25381 16.0746L8.75381 11.0746C8.71259 10.6625 9.0133 10.2949 9.42546 10.2537Z" fill="#ffffff"/> 
  <path d="M15.2464 11.0746C15.2876 10.6625 14.9869 10.2949 14.5747 10.2537C14.1626 10.2125 13.795 10.5132 13.7538 10.9254L13.2538 15.9254C13.2126 16.3375 13.5133 16.7051 13.9255 16.7463C14.3376 16.7875 14.7051 16.4868 14.7464 16.0746L15.2464 11.0746Z" fill="#ffffff"/> 
</g></svg> Delete`;
        deleteButton.className = 'delete-btn';

        const recordOptionsMain = document.querySelector('.record-options-main');
        const recordTopDiv = document.querySelector('.record-topdiv');

        deleteButton.addEventListener('click', () => {
          setTimeout(() => {
            const savedVideo = document.querySelector('.saved-video');
            console.log(savedVideo, "savedVideo")
            if (savedVideo) {
              savedVideo.style.display = '';
              savedVideo.style.display = 'none';

            }
          }, 1)
          console.log("delete btn click");
          recordOptionsMain.style.display = '';
          recordTopDiv.style.display = '';

          // Then apply the new styles
          recordOptionsMain.style.display = 'block';
          recordTopDiv.style.display = 'block';
        });

        // Append the video and delete button to the video container
        videoContainer.appendChild(videoElement);
        videoContainer.appendChild(deleteButton);

        // Append the video container to the record-container div
        document.querySelector('.record-container').appendChild(videoContainer);

        // Hide record-topdiv and record-options-main
        const topDiv = document.querySelector('.record-topdiv');
        const optionsMain = document.querySelector('.record-options-main');
        if (topDiv) {
          topDiv.style.display = 'none'; // Hide record-topdiv
        }
        if (optionsMain) {
          optionsMain.style.display = 'none';
        }

        // Programmatically click the close button
        const closeButton = document.querySelector('.close-btn');
        if (closeButton) {
          closeButton.click(); // Simulate a click on the close button
        }

        // Hide the popup after saving
        closePopup();
      }
    }, 500); // Adjust speed of progress (500ms for simulation)
  };


  const toggleRecording = (status, startRecording, stopRecording) => {
    if (status === "recording") {
      stopRecording();
    } else {
      startRecording();
    }
  };


  // Function to close the popup
  const closePopup = () => {
    document.getElementById('uploadPopup').style.display = 'none';
  };
  return (
    <div>
      <ToastContainer />
      <ReactMediaRecorder
        video
        onStart={handleStart}
        onStop={handleStop}
        render={({status, previewStream, startRecording, stopRecording }) => (
          <div>
            {/* Video Preview Section */}
            
            {previewStream && <VideoPreview stream={previewStream} />}
            {/* Upload Video Button */}
            {/* Display Recording Status */}

            <div className="button-section">
              {showUploadButtons && isFirstTime ? (
                <>
                  <button className="upload-btn" onClick={handleButtonClick}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path d="M17.707 8.293 12 2.586 6.293 8.293l1.414 1.414L11 6.414V15h2V6.414l3.293 3.293z"></path>
                      <path d="M4.5 13h2v5h11v-5h2v7h-15z"></path>
                    </svg>
                    Upload video
                    <input
                      type="file"
                      accept="video/*"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </button>

                  {/* Toggle Recording Button */}
                  <button
                    className="btn btn-dark record-btn"
                    onClick={() => toggleRecording(status, startRecording, stopRecording)}
                    disabled={!hasMediaAccess}
                  >
                    {/* {status === isRecording ? "Stop Recording" : "Start Recording"} */}
                    {status === "recording" ? "Stop Recording" : "Start Recording"}
                  </button>
                </>
              ) : (
                // Show video preview and action buttons 
                <div className="video-preview-section">
                  {
                     <video
                      playsInline
                      controls
                      src={uploadedVideo || videoUrl}
                      style={{ width: '100%', height: 'auto', maxHeight: '400px', borderRadius: '12px' }}
                    />
                  }
                  { previewStream && <VideoPreview stream={previewStream} />}
                  {/* Retake and Save Buttons */}
                  <div className="button-group">
                    <button onClick={handleRetake} className="retake-btn">
                      Retake
                    </button>
                    <button onClick={handleSave} className="save-btn">
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Modal Popup */}
            <div id="uploadPopup" className="modall">
              <div className="upload-content">
                <span className="close" onClick={closePopup}>
                  &times;
                </span>
                <p>Video is uploading. No need to refresh the page.</p>
                <div className="upload-progress">
                  <div className="progress-bar"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

const VideoPreview = ({ stream }) => {
  return (
    <><div className='videoDiv'>
      <video
        autoPlay
        playsInline
        muted
        controls
        ref={video => { if (video) video.srcObject = stream; }}
        style={{ width: '100%', height: 'auto', maxHeight: '400px', borderRadius: '12px', background: "black" }} // Set video size
      />
    </div>
    </>

  );
};

export default VideoRecorder;

