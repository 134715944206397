import React, { useState, useEffect } from "react";
import img1 from "../assets/img1.png";
import img2 from "../assets/img2.png";
import img3 from "../assets/img3.png";
import img4 from "../assets/img4.png";
import productimg from "../assets/product.jpg";
import Cameralite from "../assets/camera-lite.png";
import Cameradark from "../assets/camera-dark.png";
import Microphonelite from "../assets/microphone-lite.png";
import Microphonedark from "../assets/microphone-dark.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import VideoRecorder from "./videoPreview";
import AudioRecorder from "./audioPreview";
export default function Addgift() {
  const [message, setMessage] = useState('');
  const [videoFirst, setVideoFirst] = useState(true);
  const [audioFirst, setAudioFirst] = useState(true);
  const [giftFrom, setGiftFrom] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [specificDate, setSpecificDate] = useState("");
  const [selectedLook, setSelectedLook] = useState({
    color: "rgb(30 56 241 / 54%)",
    image: img2,
    textcolor: "#fff" // Initial text color
  });
  const [selectedOption, setSelectedOption] = useState(
    "When the gift is dispatched"
  );
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [isAudioPopupOpen, setAudioPopupOpen] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [showBlockInfo, setShowBlockInfo] = useState(null);
  const [showAudioBlockInfo, setShowAudioBlockInfo] = useState(null);
  const [tokenUrl, setTokenUrl] = useState('');
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    // Get the current URL
    const url = new URL(window.location.href);

    // Extract the 'link' query parameter value
    const imageLink = url.searchParams.get('l');
    console.log(imageLink, "image link")
    const fullImageLink = `https://cdn.shopify.com/s/files${imageLink}`;
    if (fullImageLink) {
      setImageUrl(fullImageLink);
    }

    console.log(url.searchParams.get('t'),"completeTokn")
    const cart_token = url.searchParams.get('t')?.split("?key=")[0];
    console.log(cart_token, "cart Token")
    if (cart_token) {
      setTokenUrl(cart_token)
    }

    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((data) => {
        setShowBlockInfo(true);// Access granted
      })
      .catch(() => {
        setShowBlockInfo(false); // Access denied
      });

    navigator.mediaDevices.getUserMedia({ video: false, audio: true })
      .then((data) => {
        setShowAudioBlockInfo(true);// Access granted
      })
      .catch(() => {
        setShowAudioBlockInfo(false); // Access denied
      });
  }, []);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleMsgChange = (event) => {
    setMessage(event.target.value);
  };

  const handleFromChange = (event) => {
    setGiftFrom(event.target.value);
  };

  const handleEmailChange = (event) => {
    setRecipientEmail(event.target.value);
  };

  const handleDateChange = (e) => {
    setSpecificDate(e.target.value);
  };

  const handleSkip = () => {
    window.location.href = "https://lil-stage.myshopify.com/cart";
    // window.location.href = process.env.CART_URL;
  };

  const handleSaveAndContinue = async () => {
    toast.dismiss();
    // Basic validation checks
    if (!message) {
      toast.error("Please enter a message.");
      return;
    }

    if (!giftFrom) {
      toast.error("Please enter the sender's name.");
      return;
    }

    if (!selectedOption) {
      toast.error("Please select an option.");
      return;
    }

    if (selectedOption === "On a specific date" && (!specificDate || !recipientEmail)) {
      toast.error("Please enter the specific date and recipient's email.");
      return;
    } else if (
      (selectedOption === "Right after purchase" || selectedOption === "When the gift is dispatched")
      && !recipientEmail
    ) {
      toast.error("Please enter the recipient's email.");
      return;
    }

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log("emailRegex:", emailRegex);
    if (!emailRegex.test(recipientEmail)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    console.log("Saved video:", videoFile);
    console.log("Saved audio:", audioFile);
    let formData = new FormData();
    formData.append('giftMessage', message);
    formData.append('giftFrom', giftFrom);
    formData.append('shareOption', selectedOption);
    formData.append('cart_token', tokenUrl);

    console.log("Color Code to Save:", selectedLook.color); 
    formData.append('c_code', selectedLook.color);
    formData.append('card', selectedLook.image);

    if (isChecked) {
      formData.append('image', imageUrl);
    } else {
      formData.append('image', null);
    }


    if (selectedOption === "On a specific date") {
      formData.append('date', specificDate);
      formData.append('email', recipientEmail);
    } else if (selectedOption === "Right after purchase" || selectedOption === "When the gift is dispatched") {
      formData.append('email', recipientEmail);
    } else if (selectedOption === "Share link") {
      formData.append('shareLink', '..link');
    }

    // Check if at least one of audioFile or videoFile exists
    const appendBlobToFormData = async (blobUrl, name) => {
      if (!blobUrl) {
        console.error(`Blob URL for ${name} is missing`);
        return;
      }
      try {
        const response = await fetch(blobUrl);
        if (!response.ok) {
          throw new Error(`Failed to fetch blob for ${name}: ${response.statusText}`);
        }
        const blob = await response.blob(); 
        const mimeType = blob.type;
        const extension = mimeType?.split('/')[1];
        formData.append(name, blob, `${name}.${extension}`);
      } catch (error) {
        console.error(`Error while fetching blob for ${name}:`, error);
      }
    };

    // Append video and audio to FormData, only if they exist
    const appendTasks = [];
    if (videoFile) {
      console.log(videoFile, "------------videoFile")
      appendTasks.push(appendBlobToFormData(videoFile, 'video')); // Append video if available
    }
    if (audioFile) {
      console.log(audioFile, "------------audioFile")
      appendTasks.push(appendBlobToFormData(audioFile, 'audio')); // Append audio if available
    }
    await Promise.all(appendTasks);

    console.log("Form data.....");
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }


    try {
      setLoading(true);

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/api/recordings`, {
        method: 'POST',
        body: formData
      });
      setLoading(false);

      console.log("------enter-------")
      const data = await response.json();
      console.log(data, "------data-------")
      if (response.ok) {
        console.log('Data saved successfully', data);
        toast.success('Data saved successfully!')
        const URL = process.env.URL
        console.log(URL, "=====URL")
        window.location.href = `https://lil-stage.myshopify.com/checkouts/cn/${tokenUrl}`

      } else {
        console.error('Error saving Data', data.message);
        toast.error('Error saving Data: ' + data.message);

      }
    } catch (error) {
      setLoading(false);

      console.error('Error occurred while saving Data:', error.message);
    }
  };


  const toggleVideoPopup = () => {
    const optionsMain = document.querySelector('#uploadPopup');
    if (optionsMain) {
      optionsMain.style.display = 'none'; // Hide record-topdiv
    }
    setVideoPopupOpen(!isVideoPopupOpen);
    setIsFirstTime(true)
  };

  const toggleAudioPopup = () => {
    const optionsMain = document.querySelector('#uploadPopup');
    if (optionsMain) {
      optionsMain.style.display = 'none'; // Hide record-topdiv
    }
    setAudioPopupOpen(!isAudioPopupOpen);
    setIsFirstTime(true)
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const teaserOptions = [
    {
      color: "rgb(30 56 241 / 54%)",
      image: img2,
      label: "Just because",
      textcolor: "#fff"
    },
    {
      color: "#ADD8E6",
      image: img1,
      label: "Happy birthday",
      // textcolor: "#000"
      textcolor: "#fff"

    },
    {
      color: "rgb(224 140 173)",
      image: img3,
      label: "Halloween",
      // textcolor: "#000"
      textcolor: "#fff"

    },
    {
      color: "#f1641e",
      image: img4,
      label: "Thinking of you",
      textcolor: "#fff"
    }
  ];

  // Handler to update selected look
  const handleSelectLook = (color, image, textcolor) => {
    setSelectedLook({ color, image, textcolor });
  };

  return (
    <>
      <div className="addgift-main">
        <div className="heading-div">
          <div className="heading-divcontent">
            <h1>Add gift details</h1>
            <p>
              Keep in mind, you can skip these options or edit after purchase.
            </p>
          </div>
          <div className="heading-divbtn">
            <button onClick={handleSkip}>Skip this step</button>
          </div>
        </div>

        <div className="form-div">
          {/* <form> */}
          <div className="mb-3">
            <div className="label-div">
              <label for="exampleInputEmail1" className="form-label">
                Add a gift message
              </label>
              {/* <p>Dispatched with your order to add a personal touch</p> */}
            </div>
            <textarea
              className="form-control"
              placeholder="Write message..."
              id="floatingTextarea"
              value={message} // Step 2: Bind the state variable to the textarea value
              onChange={handleMsgChange} // Step 3: Call the handleChange function on input
            ></textarea>
            <div id="emailHelp" className="form-text">
              {message.length}/150
            </div>
          </div>
          <div className="mb-3">
            <div className="label-div">
              <label for="exampleFormControlInput1" className="form-label">
                Who's this gift from?
              </label>
              {/* <p>Write the first name as you'd like it to appear</p> */}
            </div>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Write the first name as you'd like it to appear"
              value={giftFrom}
              onChange={handleFromChange} // Step 3: Call the handleChange function on input
            />
          </div>
          <hr className="hr1" />
          <div className="mb-3">
            <div className="label-div">
              <label for="exampleFormControlInput1" className="form-label">
                Send a gift teaser straight to their inbox (*FREE)
              </label>
              <p>
                Includes gift message, tracking, and Help Centre info. *Free
                with purchase
              </p>
            </div>
          </div>

          <div className="teaser-container">
            <div className="teaser-topdiv">
              <div
                className="teaser-preview"
                style={{
                  backgroundColor: selectedLook.color,
                  padding: "20px",
                  borderRadius: "20px"
                }}
              >
                <p
                  className="teaser-message"
                  style={{
                    color: selectedLook.textcolor
                  }}
                >
                  A gift from{" "}
                  <span
                    style={{
                      color: selectedLook.textcolor
                    }}
                  >
                    {giftFrom}
                  </span>{" "}
                  is on its way!
                </p>

                <div className="teaser-card">
                  <p
                    className="teaser-details"
                    style={{
                      color: selectedLook.textcolor
                    }}
                  >
                    
                  </p>
                  <img
                    src={selectedLook.image}
                    alt="Teaser look"
                    className="teaser-image"
                  />
                </div>
                <div className="product-imgdiv">
                  {isChecked ? (
                    <img
                      className="product-img"
                      src={imageUrl}
                      alt="productimg"
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="teaser-options-main">
              <label for="exampleFormControlInput1" className="form-label">
                Select a look for your teaser
              </label>
              <div className="teaser-options-bottom">
                <div className="teaser-options">
                  {teaserOptions.map((option, index) => (
                    <div
                      key={index}
                      className={`teaser-optiondiv ${selectedLook.image === option.image ? "selected" : ""
                        }`}
                    >
                      {/* <div className="teaser-optiondiv"> */}
                      <div
                        key={index}
                        className="teaser-option"
                        onClick={() =>
                          handleSelectLook(
                            option.color,
                            option.image,
                            option.textcolor
                          )
                        }
                        style={{
                          backgroundColor: option.color,
                          cursor: "pointer"
                        }}
                      >
                        <img
                          src={option.image}
                          alt={option.label}
                          className="option-image"
                        />
                      </div>
                      <p>{option.label}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="record-container">
            <div className="record-topdiv">
              <div className="record-imgdiv">
                <img
                  src={Cameralite}
                  alt="Cameralite"
                  className="record-img1"
                />
                <img
                  src={Microphonelite}
                  alt="Microphonelite"
                  className="record-img2"
                />
              </div>
              <p>
                The perfect way to share a memorable moment in 30 seconds or
                less.
              </p>
            </div>
            <div className="record-options-main">
              <label for="exampleFormControlInput1" className="form-label">
                Record or upload a message (optional)
              </label>
              <div className="record-btndiv">
                {/* <button className="Add-video" onClick={toggleVideoPopup}> */}
                <button className="Add-video" onClick={toggleVideoPopup}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15 6a1 1 0 0 1 1 1v3l4.146-4.146a.5.5 0 0 1 .854.354v11.586a.5.5 0 0 1-.854.353L16 14v3a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1zm1.828 6L19 14.173V9.83zM14 8H5v8h9z"
                    ></path>
                  </svg>
                  Add video
                </button>
                <button className="Add-audio" onClick={toggleAudioPopup}>
                  {/* <button className="Add-audio" onClick={toggleAudioPopup}> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path d="M18 8c-.55 0-1 .45-1 1v3c0 2.41-1.72 4.43-4 4.9v-1.04c1.72-.45 3-2 3-3.86V6c0-2.21-1.79-4-4-4S8 3.79 8 6v6c0 1.85 1.28 3.41 3 3.86v1.04A5 5 0 0 1 7 12V9c0-.55-.45-1-1-1s-1 .45-1 1v3c0 3.52 2.61 6.43 6 6.92V20H8v2h8v-2h-3v-1.08c3.39-.49 6-3.4 6-6.92V9c0-.55-.45-1-1-1m-8 1V6c0-1.1.9-2 2-2s2 .9 2 2v3z"></path>
                  </svg>
                  Add audio
                </button>
              </div>
            </div>
          </div>

          <div className="form-check ckeckbox-main">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <div className="checkbox-content">
              <label className="form-check-label" for="flexCheckDefault">
                Give them a sneak peek of their gift!
              </label>
              <span>
                Includes a blurred item image they can reveal… or resist.
              </span>
            </div>
          </div>

          <div className="select-maindiv">
            <label for="exampleFormControlInput1" className="form-label">
              When should we share?
            </label>
            <select
              id="giftShareSelect"
              className="form-select"
              value={selectedOption}
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="When the gift is dispatched" selected="">
                When the gift is dispatched
              </option>
              <option value="Right after purchase">Right after purchase</option>
              <option value="On a specific date">On a specific date</option>
              <option value="Share link">Share link</option>
            </select>
            {selectedOption === "When the gift is dispatched" ||
              selectedOption === "Right after purchase" ? (
              <div className="specific-datediv">
                <div className="specific-datediv2">
                  <label htmlFor="recipientEmail" className="form-label">
                    What's their email?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recipientEmail"
                    placeholder="Enter email"
                    value={recipientEmail} // Step 2: Bind the state variable to the input value
                    onChange={handleEmailChange}
                  />
                </div>
              </div>
            ) : null}

            {selectedOption === "On a specific date" ? (
              <div className="specific-datediv">
                <div className="specific-datediv1">
                  <label for="exampleFormControlInput1" className="form-label">
                    Set specific date
                  </label>
                  <p>Send within the next 3 months. Based on your time zone</p>
                  <input
                    type="date"
                    className="form-control"
                    id="exampleFormControlInput1"
                    value={specificDate} // Bind the date input to the state variable
                    onChange={handleDateChange}
                  />
                </div>
                <div className="specific-datediv2">
                  <label for="exampleFormControlInput1" className="form-label">
                    What's their email?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter email"
                    value={recipientEmail} // Step 2: Bind the state variable to the input value
                    onChange={handleEmailChange}
                  />
                </div>
              </div>
            ) : null}

            {selectedOption === "Share link" ? (
              <p className="purchase-link">
                Your link will be ready right after purchase.
              </p>
            ) : null}
          </div>
          <div className="button-container">
            <button
              type="button"
              className="btn btn-dark save-btn"
              onClick={handleSaveAndContinue} // Attach the click handler here
            >
              { loading ? 'Please wait..' : 'Save and continue'}
            </button>
          </div>
          <p className="Policy-div">
            Keep in mind this gift teaser and any recorded or uploaded content
            needs to follow
            <a
              // target="_blank"
              rel="noopener noreferrer"
              href="#"
            >
              Etsy’s House Rules
            </a>
            . For privacy info, view our
            <a
              // target="_blank"
              rel="noopener noreferrer"
              href="#"
            >
              Privacy Policy
            </a>
            .
          </p>
          {/* </form> */}
        </div>
        <ToastContainer />
      </div>

      {isVideoPopupOpen && (
        <div className="modal-backdrop">

          <div className="modal-container">
            <div className="close-btndiv">
              <button className="close-btn" onClick={toggleVideoPopup}>
                &times;
              </button>
            </div>

            <div className="modal-content">

              {/* The content for when camera/microphone are not accessible */}
              {isFirstTime && !showBlockInfo ? (
                <>
                  <div className="modal-content1">
                    <div className="icon-section">
                      <img src={Cameradark} alt="Camera icon" />
                      <img src={Microphonedark} alt="Microphone icon" />
                    </div>
                    <h2>
                      Hm, we can’t access your microphone or camera. Try updating your
                      permissions in your browser’s settings and refreshing.
                    </h2>
                    <p>To record your video, tap the start button.</p>
                  </div>
                  <div>
                    <VideoRecorder url={setVideoFile} isFirstTime={videoFirst} retake={setVideoFirst} />
                  </div>
                </>
              ) : (
                // Second Page: Show only the video and buttons
                <div className="video-wrapper">
                  <VideoRecorder url={setVideoFile} isFirstTime={videoFirst} retake={setVideoFirst} />
                </div>
              )}

            </div>
          </div>
        </div>
      )}

      {isAudioPopupOpen && (
        <div className="modal-backdrop">

          <div className="modal-container-microphone">
            <div className="close-btndiv1">
              <button className="close-btn" onClick={toggleAudioPopup}>
                &times;
              </button>
            </div>

            <div className="modal-content">
              {isFirstTime && !showAudioBlockInfo ? (
                <>
                  {/* The content for when camera/microphone are not accessible */}
                  <div className="modal-content-microphone">
                    <div className="icon-section sec-icon">
                      <img src={Microphonedark} alt="Microphone icon" />
                    </div>
                    <h2>
                      Hm, we can’t access your microphone or camera. Try updating your
                      permissions in your browser’s settings and refreshing.
                    </h2>
                  </div>
                  <div className="video-wrapper-audio">
                    <AudioRecorder url={setAudioFile} isFirstTime={audioFirst} retake={setAudioFirst} />
                  </div>
                </>
              ) : (
                // Second Page: Show only the video and buttons
                <div className="video-wrapper">
                  <AudioRecorder url={setAudioFile} isFirstTime={audioFirst} retake={setAudioFirst} />
                </div>
              )}

            </div>
          </div>
        </div>
      )}
    </>
  );
}



